<template>
  <div
    v-editable="blok"
    class="relative mx-auto aspect-video h-auto max-w-[--width] overflow-hidden rounded border-2 border-transparent bg-secondary-dark outline outline-2 outline-offset-[-2px] outline-secondary-dark dark:bg-white dark:outline-white"
    :class="{ 'desktop:aspect-square': blok.slim }"
    :style="`--width: ${width}px;`"
  >
    <video
      :id="`video-${blok._uid}`"
      ref="videoEl"
      class="h-full object-cover object-center"
      :class="played ? 'visible opacity-100' : 'invisible opacity-0'"
      controls
      :poster="blok.previewImage?.filename"
      disablepictureinpicture
      disableremoteplayback
      controlslist="nofullscreen"
      playsinline
      preload="auto"
      :tabindex="played ? 0 : -1"
      @play="played = true"
    >
      <source
        v-if="!isSafari"
        :src="`https://static-resources.audoora.de/videos/website/${blok.video}/video.webm`"
        type="video/webm"
        media="(min-device-pixel-ratio:2)"
      />
      <source
        v-if="!isSafari"
        :src="`https://static-resources.audoora.de/videos/website/${blok.video}/video-sm.webm`"
        type="video/webm"
      />
      <source
        :src="`https://static-resources.audoora.de/videos/website/${blok.video}/video.mp4`"
        type="video/mp4"
        media="(min-device-pixel-ratio:2)"
      />
      <source :src="`https://static-resources.audoora.de/videos/website/${blok.video}/video-sm.mp4`" type="video/mp4" />
      <!-- <track :src="`https://static-resources.audoora.de/videos/website/${blok.video}/captions.vtt`" kind="captions" srclang="de" label="Untertitel"> -->
    </video>
    <button
      class="absolute left-0 top-0 z-10 size-full transition-opacity @container aria-hidden:pointer-events-none aria-hidden:opacity-0"
      :aria-controls="`video-${blok._uid}`"
      aria-label="Video starten"
      :aria-hidden="played"
      :disabled="played"
      :tabindex="played ? -1 : 0"
      type="button"
      @click="!isEditor && playVideo()"
    >
      <sb-image
        v-if="blok.previewImage?.filename"
        :blok="blok.previewImage"
        format="webp,png"
        :width="width"
        :height="height"
        decorative
        class="absolute left-0 top-0 z-[-1] size-full rounded object-cover object-center"
      />
      <nuxt-img
        v-else
        :src="`https://static-resources.audoora.de/videos/website/${blok.video}/poster.webp`"
        format="webp,png"
        :width="width"
        :height="height"
        alt=""
        class="absolute left-0 top-0 z-[-1] size-full rounded object-cover object-center"
      />
      <div
        class="text-shadow absolute left-0 top-0 flex size-full flex-col items-center justify-center gap-8 bg-secondary-dark/30 text-white"
      >
        <nuxt-icon name="play" class="text-4xl @sm:text-5xl" />
        <span class="text-xl font-semibold empty:hidden @md:text-3xl">{{ blok.previewText }}</span>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { StaticVideoStoryblok } from "~/component-types-sb"
import { isEditorKey } from "~/injection-symbols"

const props = defineProps<{ blok: StaticVideoStoryblok }>()
const emits = defineEmits<{ play: [] }>()
const isEditor = inject(isEditorKey, false)

const videoEl = ref<HTMLVideoElement>()
const played = ref(false)

const width = computed(() => Math.round(+(props.blok.width || 0 || 1024)))
const height = computed(() => width.value * 0.5626) // 16/9 aspect ratio

async function playVideo() {
  videoEl.value?.play()
  emits("play")
  await nextTick()
  videoEl.value?.focus()
}

const isSafari = ref(useDevice().isSafari)
</script>

<style scoped lang="scss">
.text-shadow {
  text-shadow: 0 0 4px theme("colors.secondary.dark");
}
</style>
